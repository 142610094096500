$(document).on("turbolinks:load", () => {
  moment.locale("pt-br");

  $.fn.dataTable.moment("DD/MM/YYYY HH:mm");
  $.fn.dataTable.moment("L", "pt-br");

  function distanceOfTimeInWordsToNow(date) {
    const now = new Date();
    const targetDate = new Date(date);
    const seconds = Math.floor((now - targetDate) / 1000);

    const intervals = {
      ano: 31536000,
      mes: 2592000,
      semana: 604800,
      dia: 86400,
      hora: 3600,
      minuto: 60,
      segundo: 1,
    };

    const colors = {
      ano: "#1d1e1f",
      mes: "#264663",
      semana: "#2d6aa3",
      dia: "#0d330d",
      hora: "#2a782a",
      minuto: "#2f9e2f",
      segundo: "#2cc92c",
    };

    for (let interval in intervals) {
      const intervalInSeconds = intervals[interval];
      const intervalDuration = Math.floor(seconds / intervalInSeconds);

      if (intervalDuration >= 1) {
        const plural = intervalDuration !== 1 ? "s" : "";

        return `<span class="map-color" style="background-color: ${colors[interval]};">há ${intervalDuration} ${interval}${plural}</span>`;
      }
    }

    return `<span class="map-color" style="background-color: '#2cc92c'";>agora mesmo</span>`;
  }

  const orderBy = {
    bills: [[7, "desc"]],
    users: [[4, "desc"]],
    orders: [[6, "desc"]],
    promotions: [[3, "asc"]],
    track_refunds: [[4, "desc"]],
    pending_orders: [[6, "desc"]],
    refund_reasons: [[3, "desc"]],
    recommended_wallets: [[0, "asc"]],
    refund_requested_bills: [[7, "desc"]],
    referrals: [[6, "desc"]],
    kits: [[3, "desc"]],
    updates: [[1, "desc"]],
  };

  $(".dtable").DataTable({
    paging: false,
    ordering: true,
    info: false,
    searching: false,
    language: {
      decimal: ",",
    },
    order: orderBy[location.pathname.split("/").pop()] || [[1, "desc"]],
  });

  $("th").click(function () {
    this.asc = !this.asc;
    if (this.asc === true) {
      $("th")
        .find(".table-order-icon")
        .removeClass("bi bi-sort-down")
        .addClass("bi-sort-down-alt");
    } else {
      $("th")
        .find(".table-order-icon")
        .removeClass("bi-sort-down-alt")
        .addClass("bi bi-sort-down");
    }
  });

  const ptBr = {
    language: {
      lengthMenu: "Mostrar registros por página _MENU_",
      zeroRecords: "Nenhum registro encontrado",
      info: "Mostrando página _PAGE_ de _PAGES_",
      infoEmpty: "Nenhum registro encontrado",
      infoFiltered: "(filtrado de _MAX_ registros)",
      emptyTable: "Nenhum registro encontrado",
      search: "Buscar:",
      loadingRecords: "Carregando...",
      processing: "Processando...",
      zeroRecords: "Nenhum registro encontrado",
      paginate: {
        previous: "Anterior",
        next: "Próximo",
      },
    },
  };

  $(".users-online").DataTable({
    order: [[5, "desc"]],
    ...ptBr,
  });

  const getAnalyticalUnloggedData = () => {
    $(".analytical-unlogged").DataTable({
      ajax: {
        url: "/admin/users/analytics_unlogged",
        dataSrc: function (json) {
          removeLoading();

          $('button[data-bs-target="#nav-analytical-unlogged"]').html(
            `Analítico - sem cadastro (${json.data.length}) <i class="bi bi-arrow-clockwise"></i>`
          );

          return json.data;
        },
      },
      columns: [
        { data: "table.name" },
        { data: "table.email" },
        { data: "table.phone" },
        { data: "table.offer_name" },
        { data: "table.coupon_name" },
        { data: "table.final_value" },
        { data: "table.created_at" },
        { data: "table.created_at", visible: false },
      ],
      columnDefs: [
        {
          targets: 1,
          render: function (data) {
            const email = data.split("-").shift();

            return `<a href="/admin/users?query%5Bid_or_name_or_email_or_cpf_or_phone_cont%5D=${email}&commit=Buscar">${email}</a>`;
          },
        },
        {
          targets: 4,
          render: function (data) {
            return data || "-";
          },
        },
        {
          targets: 6,
          render: function (data) {
            return distanceOfTimeInWordsToNow(data);
          },
        },
        {
          targets: 7,
          render: function (data) {
            return moment(data).format("L");
          },
        },
      ],
      order: [[7, "desc"]],
      ...ptBr,
    });
  };

  const getAnalyticalData = () => {
    $(".analytical").DataTable({
      ajax: {
        url: "/admin/users/analytics",
        dataSrc: function (json) {
          removeLoading();

          $('button[data-bs-target="#nav-analytical"]').html(
            `Analítico (${json.data.length}) <i class="bi bi-arrow-clockwise"></i>`
          );

          return json.data;
        },
      },
      columns: [
        { data: "entity.name" },
        { data: "entity.email" },
        { data: "assigned_user.name" },
        {
          data: "entity.phone",
          render: function (data, _type, row) {
            if (!data) return "N/A";

            let cleanedData = data.replace(/[^\d]/g, "");

            if (cleanedData.startsWith("55") && cleanedData.length === 13)
              return cleanedData;

            if (cleanedData.length < 8) return cleanedData;

            return `${row?.phone_code || "55"}${cleanedData}`;
          },
        },
        { data: "offer.name" },
        { data: "coupon_name" },
        { data: "created_at" },
        { data: "created_at", visible: false },
      ],
      columnDefs: [
        {
          targets: 0,
          render: function (data, _type, row) {
            if (row.entity_type != "User")
              return `<a href="javascript:void(0)">${data}</a>`;

            return `<a href="/admin/users/${row.entity.id}">${data}</a>`;
          },
        },
        {
          targets: 2,
          render: function (data) {
            return data || "-";
          },
        },
        {
          targets: 5,
          render: function (data) {
            return data || "-";
          },
        },
        {
          targets: 6,
          render: function (data) {
            return distanceOfTimeInWordsToNow(data);
          },
        },
      ],
      order: [[7, "desc"]],
      ...ptBr,
    });
  };

  $(".active-checkout table").on("click", "tr", function () {
    const data = $(this)
      .find("td")
      .map(function () {
        return $(this).text();
      })
      .get();

    if (data.length > 2) copyRow(data.join(" | "));
  });

  const copyRow = (rowData) => {
    if (typeof rowData === "string") {
      rowData = rowData.split(" | ");
    }

    const formattedData = rowData.map((e, i) => {
      if (i === 3) return formatPhone(e);

      return e;
    });

    const rowDataString = formattedData.join(" | ");
    const alert = $(".alert-checkout");

    navigator.clipboard
      .writeText(rowDataString)
      .then(() => {
        alert.addClass("alert-success");
        alert.text(`Dados copiados: ${formattedData[0]}`);
      })
      .catch(() => {
        alert.addClass("alert-danger");
        alert.text("Erro ao copiar o código");
      });

    alert.fadeTo(1250, 500).slideUp(500, () => alert.slideUp(500));
  };

  const formatPhone = (phone) => {
    if (!phone) return "N/A";

    const cleanedPhone = phone.replace(/[^\d]/g, "");

    if (cleanedPhone.startsWith("55")) {
      return `+${cleanedPhone}`;
    }

    if (cleanedPhone.length === 10 || cleanedPhone.length === 11) {
      return `+55${cleanedPhone}`;
    }

    return `+${cleanedPhone}`;
  };

  const getSyntheticDate = () => {
    $(".synthetic").DataTable({
      ajax: {
        url: "/admin/users/synthetic",
        dataSrc: function (json) {
          removeLoading();

          $('button[data-bs-target="#nav-synthetic"]').html(
            `Sintético (${json.data.length}) <i class="bi bi-arrow-clockwise"></i>`
          );

          return json.data;
        },
      },
      columns: [
        { data: "name" },
        { data: "email" },
        { data: "phone" },
        { data: "offer_name" },
        { data: "coupon_name" },
        { data: "qtd_visitas" },
      ],
      columnDefs: [
        {
          targets: 0,
          render: function (data, _type, row) {
            return `<a href="/admin/users/${row.id}">${data}</a>`;
          },
        },
        {
          targets: 4,
          render: function (data) {
            return data || "-";
          },
        },
      ],
      order: [[5, "desc"]],
      ...ptBr,
    });
  };

  const addLoadingIcon = (data) => $(data).find("i").toggleClass("bi-circle");

  const removeLoading = () => {
    setTimeout(() => {
      $(".active-checkout button[data-bs-toggle='tab']")
        .find("i")
        .removeClass("bi-circle")
        .addClass("bi-arrow-clockwise");

      $(".active-checkout button[data-bs-toggle='tab']").removeClass(
        "disabled"
      );
    }, 150);
  };

  getAnalyticalData();
  // getSyntheticDate();
  // getAnalyticalUnloggedData();

  $(".active-checkout button[data-bs-toggle='tab']").on("click", function () {
    $(this).addClass("disabled");
    addLoadingIcon(this);
  });

  $(".active-checkout button[data-bs-target='#nav-synthetic']").on(
    "click",
    () => {
      const existsTable = $(".synthetic tbody").length;

      existsTable == 0
        ? getSyntheticDate()
        : $(".synthetic").DataTable().ajax.reload();
    }
  );

  $(".active-checkout button[data-bs-target='#nav-analytical-unlogged']").on(
    "click",
    () => {
      const existsTable = $(".analytical-unlogged tbody").length;

      existsTable == 0
        ? getAnalyticalUnloggedData()
        : $(".analytical-unlogged").DataTable().ajax.reload();
    }
  );

  $(".active-checkout button[data-bs-target='#nav-analytical']").on(
    "click",
    () => {
      $(".analytical").DataTable().ajax.reload();
    }
  );
});
